<template>
  <WTable
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :line-numbers="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :sort-options="{
      initialSortBy: {field: 'traffic', type: 'desc'},
      enabled: true,
    }"
    :pagination-options="{
      enabled: false, // isOneColMode // info: отключена из-за неовместимости с легаси
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-row-click="handleRowClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'clients'">
        <div style="display: flex; gap: 8px;">
          <span>
            {{ props.row.clients >= 0 ? props.formattedRow[props.column.field] : '-'}}
          </span>
          <ClientTooltip v-if="props.row.clients"
            :cpeData="props.row.nativeData"
          />
        </div>
      </template>

      <template v-else-if="props.column.field === 'passerbyClients'">
        {{ props.row.passerbyClients >= 0 ? props.formattedRow[props.column.field] : '-'}}
      </template>

      <template v-else-if="props.column.field === 'cpuLoad'">
        {{ props.row.cpuLoad >= 0 ? props.formattedRow[props.column.field]  : '-'}}
      </template>

      <template v-else-if="props.column.field === 'memoryLoad'">
        {{ props.row.memoryLoad >= 0 ? props.formattedRow[props.column.field]  : '-'}}
      </template>

      <template v-else>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>
  </WTable>
</template>

<script>
/**
 * компонент - таблица статистики по cpes.
 * компонент локальный: используется только в Statcpes.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

import ClientTooltip from './ClientTooltip/ClientTooltip.vue';

export default {
  name: 'StatCpesTable',
  components: {
    ClientTooltip
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    activeRowCpeId: {
      type: String,
      default: ''
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        model: false,
        name: false,
        traffic: false,
        clients: false,
        passerbyClients: false,
        cpuLoad: false,
        memoryLoad: false,
        location: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            model: this.isTableColumnHidden.model || this.isOneColMode,
            name: this.isTableColumnHidden.name,
            traffic: this.isTableColumnHidden.traffic || this.isOneColMode,
            clients: this.isTableColumnHidden.clients || this.isOneColMode,
            passerbyClients: this.isTableColumnHidden.passerbyClients || this.isOneColMode,
            cpuLoad: this.isTableColumnHidden.cpuLoad || this.isOneColMode,
            memoryLoad: this.isTableColumnHidden.memoryLoad || this.isOneColMode,
            location: this.isTableColumnHidden.location || !this.areLocationsVisible || this.isOneColMode
          },
          additionalRules: {
            areLocationsVisible: this.areLocationsVisible
          }
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        cpesData: this.items
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowCpeId = row.nativeData.id;

      return this.activeRowCpeId === rowCpeId ? 'stats-cpe-table__active-row' : '';
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;

      const nativeData = selectedRows.map(el => {
        return el.nativeData;
      });

      this.$emit('on-selected-rows-change', nativeData);
    },
    handleRowClick(event) {
      const rowCpeId = event.row.nativeData.id;
      this.$emit('on-row-click', rowCpeId);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange({ params, sortedTableRows }) {
      const sortedNativeDataTableRows = sortedTableRows.map(rowData => rowData.nativeData);

      this.$emit('on-sort-change', { params, sortedNativeDataTableRows });
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.stats-cpe-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.stats-cpe-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}
</style>
