<template>
  <v-popover
    trigger="hover"
    placement="top-center"
    popoverClass="cpestats-table-popover"
    :popperOptions="{
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window'
        }
      }
    }"

  >
    <i class="fa fa-info-circle cpestats-table-icon" role="button"/>
    <template slot="popover">
      <ul class="cpestats-table-popover-content">
        <li>
          <b>{{ $t('statistics.statCpesTooltipSession') | capitalize }}
          </b>
        </li>
        <li>
          <small>
            <b>{{ $t('statistics.statCpesTooltipCount') }}</b>: {{ cpeData.sess_count }}
          </small>
        </li>
        <li>
          <small>
            <b>{{ $t('statistics.statCpesTooltipAvgTime') }}</b>: {{ formatTime(cpeData.sess_avg) }}
          </small>
        </li>
        <li>
          <small>
            <b>{{ $t('statistics.statCpesTooltipRx') }}</b>: {{ formatBytes(cpeData.sess_rx_bytes) }}
          </small>
        </li>
        <li>
          <small>
            <b>{{ $t('statistics.statCpesTooltipTx') }}</b>: {{ formatBytes(cpeData.sess_tx_bytes) }}
          </small>
        </li>
        <li>
          <small>
            <b>{{ $t('statistics.statCpesTooltipFirst') }}</b>: {{ cpeData.sess_first_count }}
          </small>
        </li>
        <li>
          <small>
            <b>{{ $t('statistics.statCpesTooltipReturn') }}</b>: {{ cpeData.sess_return_count }}
          </small>
        </li>
      </ul>
    </template>
  </v-popover>
</template>

<script>
/**
 * компонент - тултип колонки Clients.
 * компонент локальный: используется только в StatCpesTable.vue
 * @component
 */

import { createNamespacedHelpers } from 'vuex';

import {
  formatBytes
} from '../utils';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');

export default {
  name: 'ClientTooltip',
  components: {},
  props: {
    cpeData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...localeMapGetters(['isRuLocale'])
  },
  methods: {
    formatTime(value) {
      return this.isRuLocale
        ? moment.duration(value, 'seconds').format('D дн., h ч., mm мин., ss сек.')
        : moment.duration(value, 'seconds').format('D [day] h [h], mm [min.], ss [sec.]');
    }
  },
  created() {
    this.formatBytes = formatBytes;
  }
};
</script>

<style lang="css">
.cpestats-table-popover {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.cpestats-table-popover-content  {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cpestats-table-icon {
  color: var(--brand-info);
  transition: var(--transition);
}

.cpestats-table-icon:hover {
  color: var(--brand-primary);
}
</style>
