<template>
  <div class="row">
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-8 col-md-12 filters">-->
<!--          <div class="filters__search">-->
<!--            <div class="input-group input-group-sm">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-search"></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="search"-->
<!--                name="search"-->
<!--                :placeholder="$t('aps.search')"-->
<!--                class="form-control filters__input"-->
<!--                v-model="filters.query"-->
<!--                @input="disableStatMode()"-->
<!--              />-->
<!--              <span class="clear-input text-muted" v-if="filters.query" @click="filters.query = ''">-->
<!--                <i class="fa fa-remove"></i>-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--&lt;!&ndash;         <div class="col-lg-2 col-md-12 ">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="input-group input-group-sm filters__pagination flex-wrap mr-1">&ndash;&gt;-->
<!--&lt;!&ndash;          <div>&ndash;&gt;-->
<!--&lt;!&ndash;                <label for="page-limit" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
<!--&lt;!&ndash;                  <small>{{ $t('general.filterPageLimitSmall') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;                </label>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="d-flex flex-row input-group-sm">&ndash;&gt;-->
<!--&lt;!&ndash;            <div>&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="input-group-addon">&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="fa fa-list"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;             <div>&ndash;&gt;-->
<!--&lt;!&ndash;                <input&ndash;&gt;-->
<!--&lt;!&ndash;                  id="page-limit"&ndash;&gt;-->
<!--&lt;!&ndash;                  name="page-limit"&ndash;&gt;-->
<!--&lt;!&ndash;                  type="number"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="form-control filters__input"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="filters.page.limit"&ndash;&gt;-->
<!--&lt;!&ndash;                  @change="changeLimitInFilters"&ndash;&gt;-->
<!--&lt;!&ndash;                />&ndash;&gt;-->
<!--&lt;!&ndash;             </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;         </div>&ndash;&gt;-->
<!--        <div class="col-lg-4 col-md-12">-->
<!--                        <div class="input-group input-group-sm filters__pagination  mr-1 limit-filter-wrapper">-->
<!--                <label for="page-limit" style="margin-top: 0.6em" class="filters__label lables-in-statclients-filters mr-h">-->
<!--                  <small>{{ $t('general.filterPageLimitSmall') }}</small>-->
<!--                </label>-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-list"></i>-->
<!--                </span>-->
<!--                <input-->
<!--                  id="page-limit"-->
<!--                  name="page-limit"-->
<!--                  type="number"-->
<!--                  class="form-control filters__input"-->
<!--                  v-model="filters.page.limit"-->
<!--                  @change="changeLimitInFilters"-->
<!--                />-->
<!--              </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-lg-3 col-md-12">-->
<!--          <div class="input-group input-group-sm filters__location">-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              @input="disableStatMode()"-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              v-model="filters.company"-->
<!--              @change="changeCompany"-->
<!--            >-->
<!--              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>-->
<!--              <option v-for="company in companys" :value="company" :key="company.name">-->
<!--                {{ company.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-3 col-md-12 d-flex">-->
<!--          <div class="input-group input-group-sm filters__location input-group-for-location-select-in-statcpes&#45;&#45;filters  mr-0" v-if="filters.company === 'All companys'">-->
<!--&lt;!&ndash;            <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <select&ndash;&gt;-->
<!--&lt;!&ndash;              :title="&ndash;&gt;-->
<!--&lt;!&ndash;                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'&ndash;&gt;-->
<!--&lt;!&ndash;                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')&ndash;&gt;-->
<!--&lt;!&ndash;                  : ''&ndash;&gt;-->
<!--&lt;!&ndash;              "&ndash;&gt;-->
<!--&lt;!&ndash;              class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;              v-model="filters.location"&ndash;&gt;-->
<!--&lt;!&ndash;              @input="disableStatMode()"&ndash;&gt;-->
<!--&lt;!&ndash;              @change="refreshCpesRating"&ndash;&gt;-->
<!--&lt;!&ndash;              :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;              </option>&ndash;&gt;-->
<!--&lt;!&ndash;            </select>&ndash;&gt;-->
<!--                                           <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-statcpes&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="refreshCpesRating"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->

<!--          </div>-->
<!--          <div class="input-group input-group-sm filters__location mr-0" v-else>-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-map-marker"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              :title="-->
<!--                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'-->
<!--                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')-->
<!--                  : ''-->
<!--              "-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              v-model="filters.location"-->
<!--              @input="disableStatMode()"-->
<!--              @change="refreshCpesRating"-->
<!--            >-->
<!--              <option-->
<!--                v-for="location in locationsObjbyIds(this.filters.company.locations)"-->
<!--                :value="location.id"-->
<!--                :key="location.id"-->
<!--              >-->
<!--                {{ location.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--          &lt;!&ndash;               <div&ndash;&gt;-->
<!--          &lt;!&ndash;                 v-if="(!(this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys')) && this.filters.location !== 'All locations'"&ndash;&gt;-->
<!--          &lt;!&ndash;                 class="d-flex justify-content-start align-items-center  with-child-wrapper ">&ndash;&gt;-->
<!--          &lt;!&ndash;                <div>&ndash;&gt;-->
<!--          &lt;!&ndash;                <input&ndash;&gt;-->
<!--          &lt;!&ndash;                  :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'"&ndash;&gt;-->
<!--          &lt;!&ndash;                  :title="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'? $t('aps.filterByCompanyDisableSortingByLocationMsg') : ''"&ndash;&gt;-->
<!--          &lt;!&ndash;                  type="checkbox"&ndash;&gt;-->
<!--          &lt;!&ndash;                  v-model="filters.with_childs"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @change="refreshCpesRating"&ndash;&gt;-->
<!--          &lt;!&ndash;                />&ndash;&gt;-->
<!--          &lt;!&ndash;              </div>&ndash;&gt;-->
<!--          &lt;!&ndash;              <div>&ndash;&gt;-->
<!--          &lt;!&ndash;                <small class="with-child">{{$t('general.withChild')}}</small>&ndash;&gt;-->
<!--          &lt;!&ndash;              </div>&ndash;&gt;-->

<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="d-flex switcher-with-childs-in-stat-cpes-wrapper align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              :title="&ndash;&gt;-->
<!--&lt;!&ndash;                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'&ndash;&gt;-->
<!--&lt;!&ndash;                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')&ndash;&gt;-->
<!--&lt;!&ndash;                  : ''&ndash;&gt;-->
<!--&lt;!&ndash;              "&ndash;&gt;-->
<!--&lt;!&ndash;              class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-in-stat-cpes"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="refreshCpesRating"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-in-stat-cpes-caption">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="col-lg-3 col-md-12">-->
<!--          <div class="input-group input-group-sm filters__wlan mr-1">-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-wifi"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              class="form-control form-control-sm filters__field filters__field&#45;&#45;wlans"-->
<!--              v-model="filters.wlan"-->
<!--              @change="refreshCpesRating()"-->
<!--            >-->
<!--              <option :selected="true" :value="'All wlans'">{{ $t('aps.allWlans') }}</option>-->
<!--              <option v-for="wlan in wlansList" :value="wlan.id" :key="wlan.id">{{ wlan.ssid }}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-lg-3 col-md-12">-->
<!--          <div class="input-group input-group-sm filters__sort mr-1">-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i-->
<!--                class="fa fa-sort-amount-desc text-primary icon-sort"-->
<!--                v-if="currSortMode === 'desc'"-->
<!--                @click="toggleSortMode()"-->
<!--              ></i>-->
<!--              <i-->
<!--                class="fa fa-sort-amount-asc text-primary icon-sort"-->
<!--                v-if="currSortMode === 'asc'"-->
<!--                @click="toggleSortMode()"-->
<!--              ></i>-->
<!--            </span>-->
<!--            <select class="form-control form-control-sm filters__field" v-model="sortByFilter">-->
<!--              <option :selected="true" value="Traffic">{{ $t('statistics.byTraffic') }}</option>-->
<!--              <option value="Clients">{{ $t('statistics.byconnectedClients') }}</option>-->
<!--              <option value="Passerby Clients">{{ $t('statistics.bypasserbyClients') }}</option>-->
<!--              <option v-if="filters.wlan == 'All wlans'" value="CPU Load">{{ $t('statistics.byCPULoad') }}</option>-->
<!--              <option v-if="filters.wlan == 'All wlans'" value="Memory Load">-->
<!--                {{ $t('statistics.byMemoryLoad') }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <transition name="fade" mode="out-in">-->
<!--      <div class="row mt-1 mb-1"-->
<!--           v-if="showGoBackToDashboardButton && showSelectedInDashboardCpeName">-->
<!--        <div class="col-12">-->
<!--          <div v-if="showSelectedInDashboardCpeName" class="float-left">-->
<!--            <span>-->
<!--              <b>{{$t('statistics.filteringByCPE')}}:&nbsp;{{showSelectedInDashboardCpeName}}-->
<!--              <span class="ml-q"><i role="button" @click.stop.prevent="resetGoBackToDashboardDataAndCPEFilter" class="fa fa-close"></i></span></b>-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--        </transition>-->
<!--      <div class="row mt-1 mb-1">-->
<!--        <div class="col-12">-->
<!--          <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--            <i class="fa fa-close"></i>-->
<!--            {{ $t('general.resetAllFilters') }}-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->



    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-3 col-md-12 filters mb-h">
          <div class="">
            <div class="input-group input-group-sm">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input
                id="search"
                name="search"
                :placeholder="$t('aps.search')"
                class="form-control filters__input"
                v-model="filters.query"
                @input="disableStatMode()"
              />
              <span class="clear-input text-muted" v-show="filters.query" @click="() => {
                  resetFilterQuery();
                  refreshCpesRating();
                }">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>

<!--        <div class="col-lg-4 col-md-12">-->
<!--                        <div class="input-group input-group-sm filters__pagination  mr-1 limit-filter-wrapper">-->
<!--                <label for="page-limit" style="margin-top: 0.6em" class="filters__label lables-in-statclients-filters mr-h">-->
<!--                  <small>{{ $t('general.filterPageLimitSmall') }}</small>-->
<!--                </label>-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-list"></i>-->
<!--                </span>-->
<!--                <input-->
<!--                  id="page-limit"-->
<!--                  name="page-limit"-->
<!--                  type="number"-->
<!--                  class="form-control filters__input"-->
<!--                  v-model="filters.page.limit"-->
<!--                  @change="changeLimitInFilters"-->
<!--                />-->
<!--              </div>-->
<!--        </div>-->

        <div class="col-lg-3 col-md-12 mb-h" v-if="areLocationsVisible">
          <div class="input-group input-group-sm filters__location">
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>
            </span>
            <select
              :disabled="isSelectedDefaultLocationForRequests"
              @input="disableStatMode()"
              class="form-control form-control-sm filters__field"
              v-model="filters.company"
              @change="changeCompany"
            >
              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
              <option v-for="company in companys" :value="company" :key="company.name">
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 mb-h d-flex" v-if="areLocationsVisible">
          <div class="input-group input-group-sm filters__location input-group-for-location-select-in-statcpes--filters  mr-0" v-if="filters.company === 'All companys'">
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-map-marker"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              :title="-->
<!--                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'-->
<!--                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')-->
<!--                  : ''-->
<!--              "-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              v-model="filters.location"-->
<!--              @input="disableStatMode()"-->
<!--              @change="refreshCpesRating"-->
<!--              :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'"-->
<!--            >-->
<!--              <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--              <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                {{ location.name }}-->
<!--              </option>-->
<!--            </select>-->
                                           <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-statcpes--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                           v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshCpesRating"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>

          </div>
          <div class="input-group input-group-sm filters__location mr-0" v-else>
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-map-marker"></i>
            </span>
            <select
              :title="
                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'
                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')
                  : ''
              "
              class="form-control form-control-sm filters__field"
              v-model="filters.location"
              @input="disableStatMode()"
              @change="refreshCpesRating"
            >
              <option
                v-for="location in locationsObjbyIds(this.filters.company.locations)"
                :value="location.id"
                :key="location.id"
              >
                {{ location.name }}
              </option>
            </select>
          </div>
          <!--               <div-->
          <!--                 v-if="(!(this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys')) && this.filters.location !== 'All locations'"-->
          <!--                 class="d-flex justify-content-start align-items-center  with-child-wrapper ">-->
          <!--                <div>-->
          <!--                <input-->
          <!--                  :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'"-->
          <!--                  :title="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'? $t('aps.filterByCompanyDisableSortingByLocationMsg') : ''"-->
          <!--                  type="checkbox"-->
          <!--                  v-model="filters.with_childs"-->
          <!--                  @change="refreshCpesRating"-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <small class="with-child">{{$t('general.withChild')}}</small>-->
          <!--              </div>-->

          <!--            </div>-->
<!--          <div class="d-flex switcher-with-childs-in-stat-cpes-wrapper align-items-center">-->
<!--            <div-->
<!--              :title="-->
<!--                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'-->
<!--                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')-->
<!--                  : ''-->
<!--              "-->
<!--              class="d-flex align-items-end justify-content-start"-->
<!--            >-->
<!--              <Switch-component-->
<!--                :disabled="this.filters.location === 'All locations'"-->
<!--                v-model="filters.with_childs"-->
<!--                class="switcher-with-childs-in-stat-cpes"-->
<!--                @change="refreshCpesRating"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="input-group input-group-sm filters__location switcher-with-childs-in-stat-cpes-caption">-->
<!--              <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--            </div>-->
<!--          </div>-->
        </div>
<!--        <div class="col-lg-3 col-md-12 mb-h">-->
<!--          <div class="input-group input-group-sm filters__wlan mr-1">-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-wifi"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              class="form-control form-control-sm filters__field filters__field&#45;&#45;wlans"-->
<!--              v-model="filters.wlan"-->
<!--              @change="refreshCpesRating()"-->
<!--            >-->
<!--              <option :selected="true" :value="'All wlans'">{{ $t('aps.allWlans') }}</option>-->
<!--              <option v-for="wlan in wlansList" :value="wlan.id" :key="wlan.id">{{ wlan.ssid }}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->

        <div class="col-lg-3 col-md-12 mb-h multiselect-wrapper-for-select-wlans-in-statcpes" >
          <div class="input-group input-group-sm filters__wlan mr-1" >
                              <div class="d-flex w-100">
                    <div>
                      <span class="input-group-addon filters__field-icon">
                        <i class="fa fa-wifi"></i>
                      </span>
                    </div>
                    <MultiselectWrapperForUseInFilters >
                      <Multiselect
                        id="wlan-filter"
                        class=" multiselector-in-statclients"
                        v-model="selectedWlanInMultiselector"
                        :multiple="true"
                        :max="1"
                        @input="selectWlanInMultiselector"
                        :options="wlansList || []"
                        trackBy="id"
                        label="ssid"
                        :disabled="isDisable"
                        :placeholder="$t('aps.allWlans')"
                        :selectLabel="$t('general.select')"
                        deselectLabel="x"
                        selectedLabel=""
                      >
                        <template v-slot:noOptions>
                          {{ $t('general.notFound') }}
                        </template>
                        <template v-slot:noResult>
                          {{ $t('general.notFound') }}
                        </template>
                      </Multiselect>
                    </MultiselectWrapperForUseInFilters>
                  </div>
          </div>
        </div>





      </div>
      <transition name="fade" mode="out-in">
      <div class="row mb-1"
           v-if="showGoBackToDashboardButton && showSelectedInDashboardCpeName">
        <div class="col-12">
          <div v-if="showSelectedInDashboardCpeName" class="float-left">
            <span>
              <b>{{$t('statistics.filteringByCPE')}}:&nbsp;{{showSelectedInDashboardCpeName}}
              <span class="ml-q"><i role="button" @click.stop.prevent="resetGoBackToDashboardDataAndCPEFilter" class="fa fa-close"></i></span></b>
            </span>
          </div>
        </div>
      </div>
        </transition>
      <div class="row  mb-1">
        <div class="col-12">
          <WButton secondary outline sm
            v-if="isFiltersActive"
            @click="resetAllFilters"
          >
            <i class="fa fa-close"></i>
            {{ $t('general.resetAllFilters') }}
          </WButton>
        </div>
      </div>
    </div>


    <div v-if="showGoBackToDashboardButton" class=" col-lg-12 mt-1 mb-1 pr-q pl-q">
      <div class="col-lg-12">
<!--        <div v-if="showSelectedInDashboardCpeName" class="float-left">-->
<!--          <h3>-->
<!--            {{$t('events.cpeName')}}:&nbsp;{{showSelectedInDashboardCpeName}}-->
<!--          </h3>-->
<!--        </div>-->
        <div class="float-right">
          <WButton
            @click="goBackToDashboard"
          >
            {{ $t('dashboard.goBackToDashboard') }}
          </WButton>
        </div>
      </div>
    </div>


    <div class="col-lg-12">
      <div class="cpes" :class="{ 'stat-visible': statVisible }">
        <div class="cpes-rating-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div v-if="(isCpesSessionsLoading || isCpesForStatLoading || isCpesRatingLoading) && !showGoBackToDashboardButton">
                    <Loader-spinner></Loader-spinner>
                  </div>
                  <div v-if="isLoadingAll && showGoBackToDashboardButton">
                    <Loader-spinner></Loader-spinner>
                  </div>
                  <div class="card-block">
                    <StatCpesTable
                      :items="cpesRatingListFull"
                      :activeRowCpeId="statCpeId"
                      :isDataLoading="false"
                      :isDisabled="false"
                      :isOneColMode="Boolean(statVisible)"
                      @on-row-click="toggleEdit"
                      @on-sort-change="handleSortChange"
                    >
                    <template v-slot:table-menu>
                      <div class="control-panel">
                        <!-- <div class="control-panel__container left"></div> -->
                        <div class="control-panel__container right">
                          <div>
                            <date-picker
                              v-if="!statVisible"
                              v-model="drPickerDateForRating"
                              :onChange="handleDateChangeForRating.bind(this)"
                              @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForRating"
                              :show-error="isShowingDateRangeLimitExceededErrorForRating"
                              enable-max-date-range-limit
                            />
                            <div
                              v-show="isShowingDateRangeLimitExceededErrorForRating === true && !statVisible"
                              class="mb-h mb-1"
                            >
                              <span
                                style="font-size: 0.8em;"
                                v-show="isShowingDateRangeLimitExceededErrorForRating === true"
                                class="help is-danger"
                              >
                                {{ $t('general.dateRangeLimitExceededError') }}
                                {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForRating).humanize() }}
                              </span>
                            </div>
                          </div>
                          <TableDataDownloader
                            v-show="cpesRatingListFull && !statVisible"
                            :data="formatToCSV(filteredItems)"
                            :data-for-excel="formatAllStatsToExcel()"
                            :filename="getFileNameForDownloadFiles($t('downloadFilesNames.statCPEs'))"
                            :availableReports="{
                              csv: true,
                              xlsx: {
                                enabled: true,
                                autoColumnsWidth: true
                              },
                              pdf: {
                                enabled: true,
                                columnsWidth: pdfTableColumnsWidths
                              }
                            }"
                          />
                        </div>
                      </div>
                    </template>
                  </StatCpesTable>
                  <!-- todo: отрефакторить pagination внутри WTable после рефакторинга метода запроса данных getCpesRatingWithLocationFilterWithPagination -->
                  <transition>
                    <div
                      v-if=" !Boolean(statVisible) &&
                        this.canLoadMore && this.cpesRatingListFull &&
                        this.cpesRatingListFull.length &&
                        this.totalResult - this.cpesRatingListFull.length
                      "
                      class="card-block text-center p-0 mt-1"
                    >
                      <WButton fullWidth
                        @click="loadMore"
                      >
                        {{ this.$t('portal.paginationLoad') }}
                        <span v-if="this.filters.page.limit < this.totalResult - this.cpesRatingListFull.length">
                          {{ this.filters.page.limit }}
                        </span>
                        <span v-else>{{ this.totalResult - this.cpesRatingListFull.length }}</span>
                        {{ this.$t('statistics.paginationCPERaitngsOutOf') }}
                        {{ this.totalResult - this.cpesRatingListFull.length }}
                      </WButton>
                    </div>
                  </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cpes-stat" id="cpe-stat" v-if="statVisible">
          <div class="row">
            <div class="col-lg-12">
              <div class="card sticky-top">
                <!--                <div class="loader loader-backdrop loader-backdrop-statcpes" v-if="isStatsLoading"></div>-->
                <div v-if="isStatsLoading && !showGoBackToDashboardButton"><Loader-spinner></Loader-spinner></div>
                <div v-if="isLoadingAll && showGoBackToDashboardButton">
                    <Loader-spinner></Loader-spinner>
                  </div>
                <div class="card-header">
                  <img class="img img-responsive cpe-logo" src="/static/img/router.svg" alt />
                  <span v-if="cpeStatCurrent && cpeStatCurrent.name">{{ cpeStatCurrent.name }}</span>
                  <span v-else>id: {{ cpeSelectedId }}</span>
                  <small v-if="this.filters.wlan != 'All wlans'" class>
                    (
                    <i class="fa fa-wifi"></i>
                    {{ wlanObj(this.filters.wlan).ssid }} )
                  </small>
                  <small v-else class>
                    (
                    <i class="fa fa-wifi"></i>
                    {{ $t('general.allWlans') }} )
                  </small>
                  <WButton secondary outline sm
                    customClass="float-right"
                    @click="disableStatMode"
                    data-html2canvas-ignore
                  >
                    <i class="fa fa-close"></i>
                  </WButton>
                </div>
                <div class="card-block">
                  <div class="row">
                    <div class="col-lg-12 d-flex align-items-start justify-content-between flex-wrap" data-html2canvas-ignore>
                      <div class="float-left">
                        <WButton secondary
                          v-if="showGoToCpesButton"
                          @click="goToCpes"
                        >
                          {{ $t('statistics.goToCPESettings') }}
                        </WButton>
                      </div>
                      <div class="d-flex justify-content-end align-items-start">
                        <div>
                          <date-picker
                            v-model="drPickerDateForStat"
                            :onChange="handleDateChangeForStat.bind(this)"
                            @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForStat"
                            :show-error="isShowingDateRangeLimitExceededErrorForStat"
                            enable-max-date-range-limit
                          />
                          <div v-show="isShowingDateRangeLimitExceededErrorForStat === true" class="mb-h mb-1">
                            <span
                              style="font-size: 0.8em;"
                              v-show="isShowingDateRangeLimitExceededErrorForStat === true"
                              class="help is-danger"
                            >
                              {{ $t('general.dateRangeLimitExceededError') }}
                              {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForStat).humanize() }}
                            </span>
                          </div>
                        </div>
                        <div class="ml-h">
                          <TableDataDownloader
                            :data="formatToCSV(filteredItems)"
                            filename="stat-cpes"
                            :availableReports="{
                              csv: true,
                              xlsx: false,
                              pdf: false,
                              consolidated: {
                                enabled: true,
                                selector: '#cpe-stat',
                                customFilename: getFileNameForDownloadFiles(`${$t('downloadFilesNames.statCPEs')}-${nameForSavingFiles}`)
                              }
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row mt-1">
                      <div class="col-lg-12">
                        <highcharts :options="txRxChart" id="txRxChart" ref="highcharts"></highcharts>
                        <SavePngButton
                          class="save-icon color-brand"
                          id="txRxChart"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statCPEs')}-${nameForSavingFiles}_${$t('statistics.txRxChartTitle')}`)"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <highcharts :options="txRxRateChart" id="txRxRateChart" ref="highcharts"></highcharts>
                        <SavePngButton
                          class="save-icon color-brand"
                          id="txRxRateChart"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statCPEs')}-${nameForSavingFiles}_${$t('statistics.txRxRateChartTitle')}`)"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <highcharts
                          :options="txRxRateChartPerUser"
                          id="txRxRateChartPerUser"
                          ref="highcharts"
                        ></highcharts>
                        <SavePngButton
                          class="save-icon color-brand"
                          id="txRxRateChartPerUser"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statCPEs')}-${nameForSavingFiles}_${$t('statistics.txRxRatePerUserChartTitle')}`)"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <highcharts :options="clientsChart" id="clientsChart" ref="highcharts"></highcharts>
                        <SavePngButton
                          class="save-icon color-brand"
                          id="clientsChart"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statCPEs')}-${nameForSavingFiles}_${$t('statistics.totalClientsChartTitle')}`)"
                        />
                      </div>
                    </div>
                    <!--<div class="row mt-3">-->
                    <!--<div class="col-lg-12">-->
                    <!--<highcharts :options="uniqueClientsChart" ref="highcharts"></highcharts>-->
                    <!--</div>-->
                    <!--</div>-->
                    <div
                      class="row mt-3"
                      v-if="
                        !cpeStatCurrent.model.short ||
                        (cpeStatCurrent.model.short && !cpeStatCurrent.model.short.toLowerCase().includes('cisco'))
                      "
                    >
                      <div class="col-lg-12">
                        <highcharts :options="cpuCharts" id="cpuCharts" ref="highcharts"></highcharts>
                        <SavePngButton
                          class="save-icon color-brand"
                          id="cpuCharts"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statCPEs')}-${nameForSavingFiles}_${$t('statistics.cpuLoadChartTitle')}`)"
                        />
                      </div>
                    </div>
                    <div
                      class="row mt-3"
                      v-if="
                        !cpeStatCurrent.model.short ||
                        (cpeStatCurrent.model.short && !cpeStatCurrent.model.short.toLowerCase().includes('cisco'))
                      "
                    >
                      <div class="col-lg-12">
                        <highcharts :options="memoryCharts" id="memoryCharts" ref="highcharts"></highcharts>
                        <SavePngButton
                          class="save-icon color-brand"
                          id="memoryCharts"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statCPEs')}-${nameForSavingFiles}_${$t('statistics.memoryLoadChartTitle')}`)"
                        />
                      </div>
                    </div>
                    <div class="actions-buttons">
                      <div>
                        <WButton secondary outline
                          @click="disableStatMode"
                        >
                          {{ $t('general.close') }}
                        </WButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import Multiselect from 'vue-multiselect';
import wlanService from '@/services/wlanService';
import statCpeService from '@/services/statCpeService';
import commonService from '@/services/commonService';
import locationService from '@/services/locationService';

import Filters from '@/filters';
// import drPicker from '../../components/date-range-picker';
import DatePicker from '@/components/date-picker.vue';
import SavePngButton from '@/components/save-png-button.vue';
import SwitchComponent from '@/components/Universal/Switch-component.vue';
import helpers from '@/helpers';
import store from '@/store';
import SelectComponentV3ForUseInLocationSelectors from '@/components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import SelectComponentV2 from '@/components/Universal/select/select-componentV2.vue';
import MultiselectWrapperForUseInFilters from '@/components/general/filters/MultiselectWrapperForUseInFilters.vue';

import {
  StatCpesTable
} from './components';

import { intervalUpdateModeMixin, debounceMixin, searchBarMixin } from '@/mixins';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');

export default {
  name: 'Statcpes',
  components: {
    SavePngButton,
    DatePicker,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors,
    Multiselect,
    MultiselectWrapperForUseInFilters,
    StatCpesTable
  },
  mixins: [intervalUpdateModeMixin, debounceMixin, searchBarMixin],
  data() {
    return {
      // pdfTableColumnsWidths: ['auto', 'auto', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', 'auto', 'auto'],
      pdfTableColumnsWidths: ['6%', '10%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%'],
      selectedWlanInMultiselector: false,
      showLocationFilter: true,
      locationInFiltersData: [],
      showGoBackToDashboardButton: false,
      showSelectedInDashboardCpeName: '',
      offset: 0,
      totalResult: 0,
      canLoadMore: false,
      isShowingDateRangeLimitExceededErrorForRating: false,
      dateRangeLimitExceededErrorRangeLimitForRating: 0,
      isShowingDateRangeLimitExceededErrorForStat: false,
      dateRangeLimitExceededErrorRangeLimitForStat: 0,
      cpesObject: {},
      searchQuery: '',
      enableStats: '',
      selectedCpes: [],
      sortedCpesRatingListFull: [],
      statCpeId: '',
      currentCpeStats: [],
      drPickerDateForRating: {
        startDate: +moment().startOf('day').valueOf(),
        endDate: +moment().endOf('day').valueOf()
      },
      drPickerDateForStat: {
        startDate: '',
        endDate: ''
      },
      filters: {
        cpeId: false,
        query: '',
        interfacesFilter: null,
        connectedFilter: null,
        location: 'All locations',
        wlan: 'All wlans',
        company: 'All companys',
        with_childs: false,
        page: {
          limit: 100,
          offset: 0
        },
      },
      txRxChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
          text: this.$t('statistics.txRxChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        tooltip: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
          pointFormat: `{series.name}: <b>{point.y} ${this.$t('statistics.txRxChartMbytes')}</b>`
        },
        yAxis: {
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            text: this.$t('statistics.txRxChartMbytes')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            name: this.$t('statistics.txRxChartTX'),
            data: [],
            color: '#0277BD'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            name: this.$t('statistics.txRxChartRX'),
            data: [],
            color: '#81C784'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            name: this.$t('statistics.txRxChartTXandRX'),
            data: [],
            color: '#E57373'
          }
        ]
      },

      cpuCharts: {
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnCpuChart
          text: this.$t('statistics.cpuLoadChartTitle'),
          x: -20 // center
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnCpuChart
            text: this.$t('statistics.cpuLoadChartAxis')
          },
          labels: {
            formatter() {
              return `${this.value}%`;
            }
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnCpuChart
          text: this.$t('statistics.pan')
        },
        tooltip: {
          pointFormat: '<b>{point.y} %</b>'
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnCpuChart
            name: this.$t('statistics.cpuLoadChartSeries'),
            color: '#E57373',
            data: []
          }
        ]
      },

      clientsChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x'
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnClientsChart
          text: this.$t('statistics.totalClientsChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          allowDecimals: false,
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnClientsChart
            text: this.$t('statistics.totalClientsChartAxis')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnClientsChart
            name: this.$t('statistics.totalClientsChartSeries'),
            data: [],
            color: '#20a8d8'
          }
        ]
      },

      uniqueClientsChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: 'Unique Clients',
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: 'Clients'
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        series: [
          {
            name: 'Clients',
            data: [],
            color: '#81C784'
          }
        ]
      },

      memoryCharts: {
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnMemoryCharts
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnMemoryCharts
          text: this.$t('statistics.memoryLoadChartTitle'),
          x: -20 // center
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnMemoryCharts
            text: this.$t('statistics.memoryLoadChartAxis')
          },
          labels: {
            formatter() {
              return `${this.value}%`;
            }
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        tooltip: {
          pointFormat: '<b>{point.y} %</b>'
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnMemoryCharts
            name: this.$t('statistics.memoryLoadChartSeries'),
            color: '#0277BD',
            data: []
          }
        ]
      },

      txRxRateChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChart
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChart
          text: this.$t('statistics.txRxRateChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChart
            text: this.$t('statistics.txRxRateChartMbytess')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChart
            name: this.$t('statistics.txRxRateChartTXRate'),
            data: [],
            color: '#0277BD'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChart
            name: this.$t('statistics.txRxRateChartRXRate'),
            data: [],
            color: '#81C784'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChart
            name: this.$t('statistics.txRxRateChartTXandRXRate'),
            data: [],
            color: '#E57373'
          }
        ]
      },
      txRxRateChartPerUser: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChartPerUserChart
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChartPerUserChart
          text: this.$t('statistics.txRxRatePerUserChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChartPerUserChart
            text: this.$t('statistics.txRxRatePerUserChartMbytess')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChartPerUserChart
            name: this.$t('statistics.txRxRatePerUserChartTXRate'),
            data: [],
            color: '#0277BD'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChartPerUserChart
            name: this.$t('statistics.txRxRatePerUserChartRXRate'),
            data: [],
            color: '#81C784'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxRateChartPerUserChart
            name: this.$t('statistics.txRxRatePerUserChartTXandRXRate'),
            data: [],
            color: '#E57373'
          }
        ]
      }
    };
  },
  computed: {
    ...localeMapGetters(['isRuLocale', 'locale']),
    // todo refactor
    filteredItems() {
      // let filteredCpes = items;
      // filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);

      let filteredAndSortedCpes = this.sortedCpesRatingListFull.length ? this.sortedCpesRatingListFull : this.cpesRatingListFull;

      // const searchQuery = this.filters.query;

      // if (this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys') {
      //   this.filters.location = this.filters.location = 'All locations';
      //   filteredCpes = Filters.locationFilter(filteredCpes, this.filters.company.locationId);
      // }
      // filteredCpes = Filters.locationFilter(filteredCpes, this.filters.location);
      filteredAndSortedCpes = Filters.cpeInterfaceCountFilter(filteredAndSortedCpes, this.filters.interfacesFilter);
      filteredAndSortedCpes = Filters.cpeConnectionFilter(filteredAndSortedCpes, this.filters.connectedFilter);

      return filteredAndSortedCpes;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    cpesRatingListLength() {
      return this.$store.state.cpesRatingList.length;
    },
    nameForSavingFiles() {
      if (this.cpeStatCurrent !== undefined && this.cpeStatCurrent.hasOwnProperty('name')) {
        return this.cpeStatCurrent.name;
      } if (this.cpeSelectedId !== undefined) {
        return this.cpeSelectedId;
      }
      return '';
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    // allLocationsIdAsArray() {
    //   const result = [];
    //   for (const location of this.locationsList) {
    //     result.push(location.id);
    //   }
    //   return result;
    // },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isFiltersActive() {
      return (
        this.filters.query !== '' || this.filters.cpeId !== false ||
          this.filters.interfacesFilter !== null || this.filters.connectedFilter !== null ||
          this.filters.wlan !== 'All wlans' ||
          this.filters.company !== 'All companys' ||
          this.filters.page.limit !== 100 || this.filters.page.offset !== 0 ||
        (this.areLocationsVisible ? this.filters.location !== 'All locations' : false)

      );
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    isStatsLoading() {
      return this.$store.state.loadingStats;
    },
    statVisible() {
      return this.$store.state.statCpeId;
    },
    cpesRatingListFull() {
      if (this.$store.state.cpesRatingList) {
        const { cpesRatingList } = this.$store.state;

        cpesRatingList.forEach((ratingCpe) => {
          const findCpe = commonService.cpeObjbyId(ratingCpe.id);
          if (findCpe) {
            ratingCpe.name = findCpe.name;
            ratingCpe.model = { name: findCpe.model.name };
            ratingCpe.base_location = findCpe.base_location;
            ratingCpe.connected = findCpe.connected;
          }
        });

        return cpesRatingList;
      }
      return [];
    },
    isCpesRatingLoading() {
      return this.$store.state.loadingCpesRating;
    },
    isCpesSessionsLoading() {
      return this.$store.state.loadingCpesSessions;
    },
    isCpesForStatLoading() {
      return this.$store.state.loadingCpesForStat;
    },
    isLoadingAll() {
      return this.isCpesSessionsLoading || this.isCpesForStatLoading || this.isCpesRatingLoading
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    cpeSelectedId() {
      this.statCpeId = this.$store.state.statCpeId;
      return this.$store.state.statCpeId;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    showGoToCpesButton(){
      return this.$store.state.userData.role === 'operator' ||
        this.$store.state.userData.role === 'admin';
    },
    cpeStatCurrent() {
      // console.log('cpeStatCurrent')
      let isCpeFoundInCpesList = false;
      // const { cpesList } = this.$store.state ;
      const cpesList = this.$store.state.cpesList || [];
      // let cpeObj;
      let cpeObj = { model: {} }; // if no found cpe
      cpesList.forEach((cpe) => {
        if (cpe.id === this.cpeSelectedId) {
          cpeObj = cpe;
          isCpeFoundInCpesList = true;
        }
      });
      if (isCpeFoundInCpesList === false) {
        this.cpesRatingListFull.forEach((cpe) => {
          if (cpe.id === this.cpeSelectedId) {
            cpeObj.name = cpe.name;
          }
        });
      }
      return cpeObj;
    }
  },
  watch: {
    'filters.query': function (newSearchQuery) {
      this.debounce(async () => {
        if (!newSearchQuery) {
          return;
        }

        if (this.setSearchBarQuery) {
          this.setSearchBarQuery(newSearchQuery);
        }

        this.getCpesRating();
      }, 1000);
    },
    isLoadingAll: {
      handler(val, oldVal) {
        try {
          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormDashboard')) {
            // console.log('here!!!! af sd  dsf sdf sdf sdfsdf sdf sdf fesf sef sefsef sef se fse')
            // после загрузки списка CPE если ранее  перешили в раздел CPE stat из дашборда по CPE с передачей определной точки для октрытия открываем ее в панели редактироваания
            if (Array.isArray(this.cpesRatingListFull) && this.cpesRatingListFull.length > 0 && this.cpesRatingListFull.find(cpe => {
              return cpe.id === this.$route.params.cpeIdFormDashboard;
            })) {

              this.$nextTick(() => {

                this.$store.commit('setCpesStatId', this.$route.params.cpeIdFormDashboard ?? null);
                this.showSelectedInDashboardCpeName = this.cpeStatCurrent.name;
                // this.filters.query = this.showSelectedInDashboardCpeName
                this.responseStats();
                // и удаляем чтобы этот вотчер больше не отрабатывал если список cpe обновляется (и isCpesLoading меняет состояние)
                delete this.$route.params.cpeIdFormDashboard;
              })
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    locale() {
      this.changeCaptionsWithI18nOnCharts();
    }
  },
  methods: {
    handleSortChange(event) {
      const { sortedNativeDataTableRows } = event;

      this.sortedCpesRatingListFull = sortedNativeDataTableRows;
    },
    /**
     * Меняет разнообразные подписи и имена на графике memoryCharts, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnMemoryCharts() {
      this.memoryCharts.subtitle.text = this.$t('statistics.pan');
      this.memoryCharts.title.text = this.$t('statistics.memoryLoadChartTitle');
      this.memoryCharts.yAxis.title.text = this.$t('statistics.memoryLoadChartAxis');
      this.memoryCharts.series[0].name = this.$t('statistics.memoryLoadChartSeries');
    },
    /**
     * Меняет разнообразные подписи и имена на графике cpuCharts, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnCpuChart() {
      this.cpuCharts.subtitle.text = this.$t('statistics.pan');
      this.cpuCharts.title.text = this.$t('statistics.cpuLoadChartTitle');
      this.cpuCharts.yAxis.title.text = this.$t('statistics.cpuLoadChartAxis');
      this.cpuCharts.series[0].name = this.$t('statistics.cpuLoadChartSeries');
    },
    /**
     * Меняет разнообразные подписи и имена на графике clientsChart, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnClientsChart() {
      this.clientsChart.title.text = this.$t('statistics.totalClientsChartTitle');
      this.clientsChart.yAxis.title.text = this.$t('statistics.totalClientsChartAxis');
      this.clientsChart.series[0].name = this.$t('statistics.totalClientsChartSeries');
    },
    /**
     * Меняет разнообразные подписи и имена на графике txRxRateChartPerUser, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOntxRxRateChartPerUserChart() {
      this.txRxRateChartPerUser.subtitle.text = this.$t('statistics.pan');
      this.txRxRateChartPerUser.title.text = this.$t('statistics.txRxRatePerUserChartTitle');
      this.txRxRateChartPerUser.yAxis.title.text = this.$t('statistics.txRxRatePerUserChartMbytess');
      this.txRxRateChartPerUser.series[0].name = this.$t('statistics.txRxRatePerUserChartTXRate');
      this.txRxRateChartPerUser.series[1].name = this.$t('statistics.txRxRatePerUserChartRXRate');
      this.txRxRateChartPerUser.series[2].name = this.$t('statistics.txRxRatePerUserChartTXandRXRate');
    },
    /**
     * Меняет разнообразные подписи и имена на графике txRxChart, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOntxRxRateChart() {
      this.txRxRateChart.subtitle.text = this.$t('statistics.pan');
      this.txRxRateChart.title.text = this.$t('statistics.txRxRateChartTitle');
      this.txRxRateChart.yAxis.title.text = this.$t('statistics.txRxRateChartMbytess');
      this.txRxRateChart.series[0].name = this.$t('statistics.txRxRateChartTXRate');
      this.txRxRateChart.series[1].name = this.$t('statistics.txRxRateChartRXRate');
      this.txRxRateChart.series[2].name = this.$t('statistics.txRxRateChartTXandRXRate');
    },
    /**
     * Меняет разнообразные подписи и имена на графике txRxChart, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOntxRxChart() {
      this.txRxChart.subtitle.text = this.$t('statistics.pan');
      this.txRxChart.title.text = this.$t('statistics.txRxChartTitle');
      this.txRxChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t('statistics.txRxChartMbytes')}</b>`;
      this.txRxChart.yAxis.title.text = this.$t('statistics.txRxChartMbytes');
      this.txRxChart.series[0].name = this.$t('statistics.txRxChartTX');
      this.txRxChart.series[1].name = this.$t('statistics.txRxChartRX');
      this.txRxChart.series[2].name = this.$t('statistics.txRxChartTXandRX');
    },
    /**
     * Меняет разнообразные подписи и имена на всех отображаемых  графиках, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnCharts() {
      this.changeCaptionsWithI18nOntxRxChart();
      this.changeCaptionsWithI18nOntxRxRateChart();
      this.changeCaptionsWithI18nOntxRxRateChartPerUserChart();
      this.changeCaptionsWithI18nOnClientsChart();
      this.changeCaptionsWithI18nOnCpuChart();
      this.changeCaptionsWithI18nOnMemoryCharts();
    },
    resetGoBackToDashboardDataAndCPEFilter() {
      this.filters.cpeId = '';
      this.resetGoBackToDashboardData();
      this.refreshCpesRating();
    },
    resetGoBackToDashboardData() {
      this.showGoBackToDashboardButton = false;
      this.showSelectedInDashboardCpeName = false;
    },
    goBackToDashboard() {
      this.$router.push({name: 'Dashboard'})
    },
    goToCpes() {
      // console.log('go back to cpe');
      this.saveComponentStateDataForRestore();
      this.$router.push({ name: 'Aps', params: { cpeIdFormCpeStatisic: this.statCpeId } });
    },
    selectWlanInMultiselector(value) {
      if (value.length === 0 || value === null) {
        this.filters.wlan = 'All wlans';
      } else {
        this.filters.wlan = value[0].id;
      }
      this.refreshCpesRating()
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshCpesRating()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    saveComponentStateDataForRestore() {
      // из стора
      const cpesRatingList = JSON.parse(JSON.stringify(this.$store.state.cpesRatingList));
      const setCpesStatId = this.$store.state.statCpeId;
      const cpesList = JSON.parse(JSON.stringify(this.$store.state.cpesList));

      // из стейта компонента
      const filters = JSON.parse(JSON.stringify(this.filters));
      const drPickerDateForRating = JSON.parse(JSON.stringify(this.drPickerDateForRating));
      const drPickerDateForStat = JSON.parse(JSON.stringify(this.drPickerDateForStat));
      const locationInFiltersData =  JSON.parse(JSON.stringify(this.locationInFiltersData));
      const selectedWlanInMultiselector = JSON.parse(JSON.stringify(this.selectedWlanInMultiselector))
      // const txRxChart = JSON.parse(JSON.stringify(this.txRxChart));
      // const txRxRateChart = JSON.parse(JSON.stringify(this.txRxRateChart));
      // const txRxRateChartPerUser = JSON.parse(JSON.stringify(this.txRxRateChartPerUser));
      // const clientsChart = JSON.parse(JSON.stringify(this.clientsChart));
      // const cpuCharts = JSON.parse(JSON.stringify(this.cpuCharts));
      // const memoryCharts = JSON.parse(JSON.stringify(this.memoryCharts));
      const offset = this.offset;
      const totalResult = this.totalResult;
      const canLoadMore = this.canLoadMore;

      const showGoBackToDashboardButton = this.showGoBackToDashboardButton;
      const showSelectedInDashboardCpeName = this.showSelectedInDashboardCpeName ;


      const cpesStatisticComponentStateDataForRestoreInCpesStatisticPage = {
        cpesRatingList,
        filters,
        drPickerDateForRating,
        drPickerDateForStat,
        offset,
        totalResult,
        canLoadMore,
        setCpesStatId,
        cpesList,
        // txRxChart,
        // txRxRateChart,
        // txRxRateChartPerUser,
        // clientsChart,
        // cpuCharts,
        // memoryCharts,
        showGoBackToDashboardButton,
        showSelectedInDashboardCpeName,
        locationInFiltersData,
        selectedWlanInMultiselector

      };
      localStorage.setItem('cpesStatisticComponentStateDataForRestoreInCpesStatisticPage',
        JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage));
    },
    loadComponentStateDataForRestoreAndRestore() {
      const cpesStatisticComponentStateDataForRestoreInCpesStatisticPage = JSON.parse(localStorage.getItem('cpesStatisticComponentStateDataForRestoreInCpesStatisticPage'));
      if (cpesStatisticComponentStateDataForRestoreInCpesStatisticPage) {
        // восстанавливаем в стейт компонента
        if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.filters) {
          this.$set(this, 'filters',
            JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.filters)))
        }

        if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.drPickerDateForRating) {
          this.$set(this, 'drPickerDateForRating',
            JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.drPickerDateForRating)))
        }

        if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.drPickerDateForStat) {
          this.$set(this, 'drPickerDateForStat',
            JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.drPickerDateForStat)))
        }

        if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.locationInFiltersData) {
          this.$set(this, 'locationInFiltersData',
            JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.locationInFiltersData)))
        }

        if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.selectedWlanInMultiselector) {
          this.$set(this, 'selectedWlanInMultiselector',
            JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.selectedWlanInMultiselector)))
        }

        // if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.txRxChart) {
        //   this.$set(this, 'txRxChart',
        //     JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.txRxChart)))
        // }
        // if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.txRxRateChart) {
        //   this.$set(this, 'txRxRateChart',
        //     JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.txRxRateChart)))
        // }
        // if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.txRxRateChartPerUser) {
        //   this.$set(this, 'txRxRateChartPerUser',
        //     JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.txRxRateChartPerUser)))
        // }
        // if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.clientsChart) {
        //   this.$set(this, 'clientsChart',
        //     JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.clientsChart)))
        // }
        // if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.cpuCharts) {
        //   this.$set(this, 'cpuCharts',
        //     JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.cpuCharts)))
        // }
        // if (!!cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.memoryCharts) {
        //   this.$set(this, 'memoryCharts',
        //     JSON.parse(JSON.stringify(cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.memoryCharts)))
        // }

        this.offset = cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.offset;
        this.totalResult = cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.totalResult;
        this.canLoadMore = cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.canLoadMore;

        this.showGoBackToDashboardButton = cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.showGoBackToDashboardButton;
        this.showSelectedInDashboardCpeName = cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.showSelectedInDashboardCpeName;


        this.$nextTick(() => {
          this.statCpeId = cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.setCpesStatId ?? null;
          this.$store.commit('setCpesList', cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.cpesList ?? []);
          this.$store.commit('setCpesRatingList', cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.cpesRatingList ?? false);

          this.$nextTick(() => {
            this.$store.commit('setCpesStatId', cpesStatisticComponentStateDataForRestoreInCpesStatisticPage.setCpesStatId ?? null);
            this.responseStats();
          });
        });
      }
    },
    removeComponentStateDataForRestore() {
      localStorage.removeItem('cpesStatisticComponentStateDataForRestoreInCpesStatisticPage');
    },
    checkComponentStateDataForRestore() {
      return !!localStorage.getItem('cpesStatisticComponentStateDataForRestoreInCpesStatisticPage');
    },
    changeLimitInFilters() {
      const limit = this.filters.page.limit
      this.filters.page.limit = helpers.validatePagination(limit)
      this.refreshCpesRating();
    },
    loadMore() {
      this.offset++;
      this.filters.page.offset = this.offset * this.filters.page.limit;
      // console.log(this.filters.offset);
      statCpeService.getCpesRatingWithLocationFilterWithPagination(this);
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    dateRangeLimitEnabledAndExceededForRating(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForRating = true;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
        this.$store.commit('setCpesRatingList', []);
      } else {
        this.isShowingDateRangeLimitExceededErrorForRating = false;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
      }
    },
    dateRangeLimitEnabledAndExceededForStat(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForStat = true;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
        this.refreshCharts();
      } else {
        this.isShowingDateRangeLimitExceededErrorForStat = false;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
      }
    },
    changeCompany() {
      // console.log(val)
      let firstLocationIdForCompany = '';
      if (this.filters.company.hasOwnProperty('locations') && this.filters.company.locations.length > 0) {
        firstLocationIdForCompany = this.filters.company.locations[0];
        // console.log(firstLocationIdForCompany);
      } else {
        firstLocationIdForCompany = 'All locations';
        if (this.$store.state.userData.base_location !== '') {
          firstLocationIdForCompany = this.$store.state.userData.base_location;
          if (firstLocationIdForCompany) {
            this.locationInFiltersData = commonService.locationObjbyId(firstLocationIdForCompany)
          }
        }
      }
      // console.log(firstLocationIdForCompany)
      // this.updatedPage.base_location = firstLocationIdForCompany;
      // this.filters.location = firstLocationIdForCompany;
      // this.filters.with_childs = false;
      this.$set(this.filters, 'location', firstLocationIdForCompany);
      this.$set(this.filters, 'with_childs', false);
      this.refreshCpesRating();
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    locationPartyGetField(id, field) {
      try {
        const filed = field;
        const showLocationPartyFromId = commonService.showLocationPartyFromLocationId(id);
        if (showLocationPartyFromId !== undefined && showLocationPartyFromId.hasOwnProperty(`${filed}`)) {
          return showLocationPartyFromId[field];
        }
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    // resetAllFilters() {
    //   this.selectedWlanInMultiselector = false;
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.resetGoBackToDashboardData();
    //   // this.filters.cpeId = '';
    //   this.filters.cpeId = false;
    //   this.offset = 0;
    //   this.filters.page.offset = 0;
    //   this.filters.page.limit = 100;
    //
    //   this.filters.query = '';
    //   this.filters.interfacesFilter = null;
    //   this.filters.connectedFilter = null;
    //   // this.filters.location = this.userBaseLocation;
    //   this.filters.location = 'All locations';
    //   this.filters.wlan = 'All wlans';
    //   this.filters.company = 'All companys';
    //   this.filters.with_childs = false;
    //   this.refreshCpesRating();
    // },
    resetFilterQuery() {
      this.filters.query = '';

      if (this.resetSearchBarQuery) {
        this.resetSearchBarQuery();
      }
    },
    resetAllFilters() {
      this.selectedWlanInMultiselector = false;
     // this.locationInFiltersData = [];
     // this.toggleShowLocationFilter();
      this.resetGoBackToDashboardData();
      // this.filters.cpeId = '';
      this.filters.cpeId = false;
      this.offset = 0;
      this.filters.page.offset = 0;
      this.filters.page.limit = 100;

      this.resetFilterQuery();
      this.filters.interfacesFilter = null;
      this.filters.connectedFilter = null;
      // this.filters.location = this.userBaseLocation;
      // this.filters.location = 'All locations';
      this.filters.wlan = 'All wlans';
      this.filters.company = 'All companys';
      // this.filters.with_childs = false;

      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();


      this.refreshCpesRating();
    },
    toggleEdit(rowCpeId) {
      if (this.isShowingDateRangeLimitExceededErrorForStat && this.statVisible) {
        return;
      }

      this.$store.commit('setCpesStatId', rowCpeId);

      this.responseStats();
    },
    // formatToCSV(cpes) {
    //   if (!cpes) {
    //     return [];
    //   }
    //
    //   const csvCpes = JSON.parse(JSON.stringify(cpes));
    //   // console.log(csvCpes);
    //
    //   for (const cpe of csvCpes) {
    //     // console.log(cpe)
    //     let companyName = '';
    //     if (this.locationPartyGetField(cpe.base_location, 'name') !== '') {
    //       // console.log(this.locationPartyGetField(locationId, 'name'));
    //       companyName = this.locationPartyGetField(cpe.base_location, 'name');
    //     } else {
    //       companyName = this.$t('general.no').toLowerCase();
    //     }
    //     cpe.companyName = companyName;
    //   }
    //
    //   // return csvCpes.map((cpe) => ({
    //   //   Model: cpe.model ? (cpe.model.name ? cpe.model.name : '') : '',
    //   //   Name: cpe.name ? cpe.name : '',
    //   //   'Traffic Downloaded': this.formatBytes(cpe.traffic_rx),
    //   //   'Traffic Uploaded': this.formatBytes(cpe.traffic_tx),
    //   //   'CPU Load': cpe.cpu_load ? `${(cpe.cpu_load * 100).toFixed(2)}%` : '0%',
    //   //   'Memory Load':
    //   //     cpe.memory_total && cpe.memory_free ?
    //   //       `${this.memoryLoad(cpe.memory_total, cpe.memory_free).toFixed(2)}%` :
    //   //       '0%',
    //   //   Location: cpe.base_location ? this.locationName(cpe.base_location) : '',
    //   //   Company: cpe.companyName,
    //   //   'Passerby Clients': cpe.passerby_clients ? cpe.passerby_clients : 0,
    //   //   Clients: cpe.sess_clients_count ? cpe.sess_clients_count : 0,
    //   //   'Session Average Time': cpe.sess_avg ? cpe.sess_avg : 0,
    //   //   'Session Count': cpe.sess_count ? cpe.sess_count : 0,
    //   //   'Session First Clients': cpe.sess_first_count ? cpe.sess_first_count : 0,
    //   //   'Session Return Clients': cpe.sess_return_count ? cpe.sess_return_count : 0
    //   // }));
    //   const result = csvCpes.map((cpe) => {
    //     const mapCpeReult = {};
    //     mapCpeReult[this.$t('general.model')] = cpe.model ? (cpe.model.name ? cpe.model.name : '') : '';
    //     mapCpeReult[this.$t('general.name')] = cpe.name ? cpe.name : '';
    //     mapCpeReult[this.$t('general.trafficDownloaded')] = this.formatBytes(cpe.traffic_rx);
    //     mapCpeReult[this.$t('general.trafficUploaded')] = this.formatBytes(cpe.traffic_tx);
    //     mapCpeReult[this.$t('general.cpuLoad')] = cpe.cpu_load ? `${(cpe.cpu_load * 100).toFixed(2)}%` : '0%';
    //     mapCpeReult[this.$t('general.memoryLoad')] =
    //       cpe.memory_total && cpe.memory_free ?
    //         `${this.memoryLoad(cpe.memory_total, cpe.memory_free).toFixed(2)}%` :
    //         '0%';
    //     // mapCpeReult[this.$t('general.location')] = cpe.base_location ? this.locationName(cpe.base_location) : '';
    //     // mapCpeReult[this.$t('general.company')] = cpe.companyName ? cpe.companyName : this.$t('general.no').toLowerCase(); ;
    //     mapCpeReult[this.$t('statistics.passerbyClients')] = cpe.passerby_clients ? cpe.passerby_clients : 0;
    //     mapCpeReult[this.$t('statistics.clients')] = cpe.sess_clients_count ? cpe.sess_clients_count : 0;
    //     mapCpeReult[this.$t('general.sessionAverageTime')] = cpe.sess_avg ? cpe.sess_avg : 0;
    //     mapCpeReult[this.$t('general.sessionCount')] = cpe.sess_count ? cpe.sess_count : 0;
    //     mapCpeReult[this.$t('general.sessionFirstClients')] = cpe.sess_first_count ? cpe.sess_first_count : 0;
    //     mapCpeReult[this.$t('general.sessionReturnClients')] = cpe.sess_return_count ? cpe.sess_return_count : 0;
    //     mapCpeReult[this.$t('general.location')] = cpe.base_location ? this.locationName(cpe.base_location) : '';
    //     mapCpeReult[this.$t('general.company')] = cpe.companyName ? cpe.companyName : this.$t('general.no').toLowerCase(); ;
    //
    //     return mapCpeReult;
    //     // Model: cpe.model ? (cpe.model.name ? cpe.model.name : '') : '',
    //     // Name: cpe.name ? cpe.name : '',
    //     // 'Traffic Downloaded': this.formatBytes(cpe.traffic_rx),
    //     // 'Traffic Uploaded': this.formatBytes(cpe.traffic_tx),
    //     // 'CPU Load': cpe.cpu_load ? `${(cpe.cpu_load * 100).toFixed(2)}%` : '0%',
    //     // 'Memory Load':
    //     //   cpe.memory_total && cpe.memory_free ?
    //     //     `${this.memoryLoad(cpe.memory_total, cpe.memory_free).toFixed(2)}%` :
    //     //     '0%',
    //     // Location: cpe.base_location ? this.locationName(cpe.base_location) : '',
    //     // Company: cpe.companyName,
    //     // 'Passerby Clients': cpe.passerby_clients ? cpe.passerby_clients : 0,
    //     // Clients: cpe.sess_clients_count ? cpe.sess_clients_count : 0,
    //     // 'Session Average Time': cpe.sess_avg ? cpe.sess_avg : 0,
    //     // 'Session Count': cpe.sess_count ? cpe.sess_count : 0,
    //     // 'Session First Clients': cpe.sess_first_count ? cpe.sess_first_count : 0,
    //     // 'Session Return Clients': cpe.sess_return_count ? cpe.sess_return_count : 0
    //   });
    //   return result;
    // },

        formatToCSV(cpes) {
      if (!cpes) {
        return [];
      }

      const csvCpes = JSON.parse(JSON.stringify(cpes));
      // console.log(csvCpes);

      for (const cpe of csvCpes) {
        // console.log(cpe)
        let companyName = '';
        if (this.locationPartyGetField(cpe.base_location, 'name') !== '') {
          // console.log(this.locationPartyGetField(locationId, 'name'));
          companyName = this.locationPartyGetField(cpe.base_location, 'name');
        } else {
          companyName = this.$t('general.no').toLowerCase();
        }
        cpe.companyName = companyName;
      }

      const result = csvCpes.map((cpe) => {
        const mapCpeReult = {};
        mapCpeReult[this.$t('general.model')] = cpe.model ? (cpe.model.name ? cpe.model.name : '') : '';
        mapCpeReult[this.$t('general.name')] = cpe.name ? cpe.name : '';
        mapCpeReult[this.$t('general.trafficDownloaded')] = this.formatBytes(cpe.traffic_rx);
        mapCpeReult[this.$t('general.trafficUploaded')] = this.formatBytes(cpe.traffic_tx);
        mapCpeReult[this.$t('general.cpuLoad')] = cpe.cpu_load ? `${(cpe.cpu_load * 100).toFixed(2)}%` : '0%';
        mapCpeReult[this.$t('general.memoryLoad')] =
          cpe.memory_total && cpe.memory_free ?
            `${this.memoryLoad(cpe.memory_total, cpe.memory_free).toFixed(2)}%` :
            '0%';
        // mapCpeReult[this.$t('general.location')] = cpe.base_location ? this.locationName(cpe.base_location) : '';
        // mapCpeReult[this.$t('general.company')] = cpe.companyName ? cpe.companyName : this.$t('general.no').toLowerCase(); ;
        mapCpeReult[this.$t('statistics.passerbyClients')] = cpe.passerby_clients ? cpe.passerby_clients : 0;
        mapCpeReult[this.$t('statistics.clients')] = cpe.sess_clients_count ? cpe.sess_clients_count : 0;
        mapCpeReult[this.$t('general.sessionAverageTime')] = cpe.sess_avg ? this.formatTime(cpe.sess_avg) : 0;
        mapCpeReult[this.$t('general.sessionCount')] = cpe.sess_count ? cpe.sess_count : 0;
        mapCpeReult[this.$t('general.sessionFirstClients')] = cpe.sess_first_count ? cpe.sess_first_count : 0;
        mapCpeReult[this.$t('general.sessionReturnClients')] = cpe.sess_return_count ? cpe.sess_return_count : 0;
        mapCpeReult[`${this.$t('statistics.userData')}: ${this.$t('statistics.receivedShort').toLowerCase()}`] = cpe.sess_rx_bytes ? this.formatBytes(cpe.sess_rx_bytes) : 0;
        mapCpeReult[`${this.$t('statistics.userData')}: ${this.$t('statistics.transmittedShort').toLowerCase()}`] = cpe.sess_tx_bytes ? this.formatBytes(cpe.sess_tx_bytes) : 0;
        // mapCpeReult[this.$t('general.location')] = cpe.base_location ? this.locationName(cpe.base_location) : '';
        // mapCpeReult[this.$t('general.company')] = cpe.companyName ? cpe.companyName : this.$t('general.no').toLowerCase();
        if (this.areLocationsVisible) {
          mapCpeReult[this.$t('general.location')] = cpe.base_location ? this.locationName(cpe.base_location) : '';
          mapCpeReult[this.$t('general.company')] = cpe.companyName ? cpe.companyName : this.$t('general.no').toLowerCase();
        }

        return mapCpeReult;
      });
      return result;
    },

    // formatToExcel(data, graphName = 'data') {
    //   if (data.length === 0) {
    //     return { [graphName]: [] };
    //   }
    //   const result = [];
    //   data.forEach((row) => {
    //     // console.log(row)
    //     let companyName = '';
    //     if (this.locationPartyGetField(row.base_location, 'name') !== '') {
    //       // console.log(this.locationPartyGetField(locationId, 'name'));
    //       companyName = this.locationPartyGetField(row.base_location, 'name');
    //     } else {
    //       companyName = this.$t('general.no').toLowerCase();
    //     }
    //     if (result.length === 0) {
    //       result.push([
    //         // 'Model',
    //         // 'Name',
    //         // 'Traffic Downloaded',
    //         // 'Traffic Uploaded',
    //         // 'CPU Load',
    //         // 'Memory Load',
    //         // 'Location',
    //         // 'Company',
    //         // 'Passerby Clients',
    //         // 'Clients',
    //         // 'Session Average Time',
    //         // 'Session Count',
    //         // 'Session First Clients',
    //         // 'Session Return Clients'
    //         this.$t('general.model'),
    //         this.$t('general.name'),
    //         this.$t('general.trafficDownloaded'),
    //         this.$t('general.trafficUploaded'),
    //         this.$t('general.cpuLoad'),
    //         this.$t('general.memoryLoad'),
    //         // this.$t('general.location'),
    //         // this.$t('general.company'),
    //         this.$t('statistics.passerbyClients'),
    //         this.$t('statistics.clients'),
    //         this.$t('general.sessionAverageTime'),
    //         this.$t('general.sessionCount'),
    //         this.$t('general.sessionFirstClients'),
    //         this.$t('general.sessionReturnClients'),
    //         this.$t('general.location'),
    //         this.$t('general.company'),
    //       ]);
    //     }
    //     result.push([
    //       row.model ? (row.model.name ? row.model.name : '') : '',
    //       row.name ? row.name : '',
    //       this.formatBytes(row.traffic_rx),
    //       this.formatBytes(row.traffic_tx),
    //       row.cpu_load ? `${(row.cpu_load * 100).toFixed(2)}%` : '0%',
    //       row.memory_total && row.memory_free ?
    //         `${this.memoryLoad(row.memory_total, row.memory_free).toFixed(2)}%` :
    //         '0%',
    //       // row.base_location ? this.locationName(row.base_location) : '',
    //       // companyName,
    //       row.passerby_clients ? row.passerby_clients : 0,
    //       row.sess_clients_count ? row.sess_clients_count : 0,
    //       row.sess_avg ? row.sess_avg : 0,
    //       row.sess_count ? row.sess_count : 0,
    //       row.sess_first_count ? row.sess_first_count : 0,
    //       row.sess_return_count ? row.sess_return_count : 0,
    //       row.base_location ? this.locationName(row.base_location) : '',
    //       companyName,
    //     ]);
    //   });
    //   return { [graphName]: result };
    // },
    // formatToExcel(data, graphName = 'data') {
    //   if (data.length === 0) {
    //     return { [graphName]: [] };
    //   }
    //   const result = [];
    //   data.forEach((row) => {
    //     // console.log(row)
    //     let companyName = '';
    //     if (this.locationPartyGetField(row.base_location, 'name') !== '') {
    //       // console.log(this.locationPartyGetField(locationId, 'name'));
    //       companyName = this.locationPartyGetField(row.base_location, 'name');
    //     } else {
    //       companyName = this.$t('general.no').toLowerCase();
    //     }
    //     if (result.length === 0) {
    //       result.push([
    //         this.$t('general.model'),
    //         this.$t('general.name'),
    //         this.$t('general.trafficDownloaded'),
    //         this.$t('general.trafficUploaded'),
    //         this.$t('general.cpuLoad'),
    //         this.$t('general.memoryLoad'),
    //         // this.$t('general.location'),
    //         // this.$t('general.company'),
    //         this.$t('statistics.passerbyClients'),
    //         this.$t('statistics.clients'),
    //         this.$t('general.sessionAverageTime'),
    //         this.$t('general.sessionCount'),
    //         this.$t('general.sessionFirstClients'),
    //         this.$t('general.sessionReturnClients'),
    //         `${this.$t('statistics.userData')}: ${this.$t('statistics.received').toLowerCase()}`,
    //         `${this.$t('statistics.userData')}: ${this.$t('statistics.transmitted').toLowerCase()}`,
    //         this.$t('general.location'),
    //         this.$t('general.company')
    //       ]);
    //     }
    //     result.push([
    //       row.model ? (row.model.name ? row.model.name : '') : '',
    //       row.name ? row.name : '',
    //       this.formatBytes(row.traffic_rx),
    //       this.formatBytes(row.traffic_tx),
    //       row.cpu_load ? `${(row.cpu_load * 100).toFixed(2)}%` : '0%',
    //       row.memory_total && row.memory_free ?
    //         `${this.memoryLoad(row.memory_total, row.memory_free).toFixed(2)}%` :
    //         '0%',
    //       // row.base_location ? this.locationName(row.base_location) : '',
    //       // companyName,
    //       row.passerby_clients ? row.passerby_clients : 0,
    //       row.sess_clients_count ? row.sess_clients_count : 0,
    //       row.sess_avg ? this.formatTime(row.sess_avg) : 0,
    //       row.sess_count ? row.sess_count : 0,
    //       row.sess_first_count ? row.sess_first_count : 0,
    //       row.sess_return_count ? row.sess_return_count : 0,
    //       row.sess_rx_bytes ? this.formatBytes(row.sess_rx_bytes) : 0,
    //       row.sess_tx_bytes ? this.formatBytes(row.sess_tx_bytes) : 0,
    //       row.base_location ? this.locationName(row.base_location) : '',
    //       companyName
    //     ]);
    //   });
    //   return { [graphName]: result };
    // },

    formatToExcel(data, graphName = 'data') {
      if (data.length === 0) {
        return {[graphName]: []};
      }
      const result = [];
      data.forEach((row) => {
        // console.log(row)
        let companyName = '';
        if (this.locationPartyGetField(row.base_location, 'name') !== '') {
          // console.log(this.locationPartyGetField(locationId, 'name'));
          companyName = this.locationPartyGetField(row.base_location, 'name');
        } else {
          companyName = this.$t('general.no').toLowerCase();
        }
        if (result.length === 0) {
          if (this.areLocationsVisible) {
            result.push([
              this.$t('general.model'),
              this.$t('general.name'),
              this.$t('general.trafficDownloaded'),
              this.$t('general.trafficUploaded'),
              this.$t('general.cpuLoad'),
              this.$t('general.memoryLoad'),
              // this.$t('general.location'),
              // this.$t('general.company'),
              this.$t('statistics.passerbyClients'),
              this.$t('statistics.clients'),
              this.$t('general.sessionAverageTime'),
              this.$t('general.sessionCount'),
              this.$t('general.sessionFirstClients'),
              this.$t('general.sessionReturnClients'),
              `${this.$t('statistics.userData')}: ${this.$t('statistics.received').toLowerCase()}`,
              `${this.$t('statistics.userData')}: ${this.$t('statistics.transmitted').toLowerCase()}`,
              this.$t('general.location'),
              this.$t('general.company')
            ]);
          } else {
            result.push([
              this.$t('general.model'),
              this.$t('general.name'),
              this.$t('general.trafficDownloaded'),
              this.$t('general.trafficUploaded'),
              this.$t('general.cpuLoad'),
              this.$t('general.memoryLoad'),
              // this.$t('general.location'),
              // this.$t('general.company'),
              this.$t('statistics.passerbyClients'),
              this.$t('statistics.clients'),
              this.$t('general.sessionAverageTime'),
              this.$t('general.sessionCount'),
              this.$t('general.sessionFirstClients'),
              this.$t('general.sessionReturnClients'),
              `${this.$t('statistics.userData')}: ${this.$t('statistics.received').toLowerCase()}`,
              `${this.$t('statistics.userData')}: ${this.$t('statistics.transmitted').toLowerCase()}`,

            ]);
          }


        }
        if (this.areLocationsVisible) {
          result.push([
            row.model ? (row.model.name ? row.model.name : '') : '',
            row.name ? row.name : '',
            this.formatBytes(row.traffic_rx),
            this.formatBytes(row.traffic_tx),
            row.cpu_load ? `${(row.cpu_load * 100).toFixed(2)}%` : '0%',
            row.memory_total && row.memory_free ?
              `${this.memoryLoad(row.memory_total, row.memory_free).toFixed(2)}%` :
              '0%',
            // row.base_location ? this.locationName(row.base_location) : '',
            // companyName,
            row.passerby_clients ? row.passerby_clients : 0,
            row.sess_clients_count ? row.sess_clients_count : 0,
            row.sess_avg ? this.formatTime(row.sess_avg) : 0,
            row.sess_count ? row.sess_count : 0,
            row.sess_first_count ? row.sess_first_count : 0,
            row.sess_return_count ? row.sess_return_count : 0,
            row.sess_rx_bytes ? this.formatBytes(row.sess_rx_bytes) : 0,
            row.sess_tx_bytes ? this.formatBytes(row.sess_tx_bytes) : 0,
            row.base_location ? this.locationName(row.base_location) : '',
            companyName
          ]);
        } else {
          result.push([
            row.model ? (row.model.name ? row.model.name : '') : '',
            row.name ? row.name : '',
            this.formatBytes(row.traffic_rx),
            this.formatBytes(row.traffic_tx),
            row.cpu_load ? `${(row.cpu_load * 100).toFixed(2)}%` : '0%',
            row.memory_total && row.memory_free ?
              `${this.memoryLoad(row.memory_total, row.memory_free).toFixed(2)}%` :
              '0%',
            // row.base_location ? this.locationName(row.base_location) : '',
            // companyName,
            row.passerby_clients ? row.passerby_clients : 0,
            row.sess_clients_count ? row.sess_clients_count : 0,
            row.sess_avg ? this.formatTime(row.sess_avg) : 0,
            row.sess_count ? row.sess_count : 0,
            row.sess_first_count ? row.sess_first_count : 0,
            row.sess_return_count ? row.sess_return_count : 0,
            row.sess_rx_bytes ? this.formatBytes(row.sess_rx_bytes) : 0,
            row.sess_tx_bytes ? this.formatBytes(row.sess_tx_bytes) : 0,

          ]);
        }

      });
      return {[graphName]: result};
    },

    formatAllStatsToExcel() {
      return {
        ...this.formatToExcel(this.filteredItems, this.$t('general.cpes'))
      };
    },
    formatBytes(bytes, decimals) {
      // return commonService.formatBytes(bytes, decimals);
      return commonService.formatBytesWithI18N(bytes, decimals)
    },
    percentRxTx(rx, tx) {
      const rxPercent = (rx * 100) / (rx + tx);
      if (rxPercent > 1) {
        return Math.round(rxPercent);
      }
      return Math.round(rxPercent * 10) / 10;
    },
    memoryLoad(total, free) {
      return ((total - free) * 100) / total;
    },
    setFilter(filterName, value) {
      this.selectedCpes = [];
      this.selectedCpesId = [];

      //                this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    totalTraffic(item) {
      const { cpesRatingList } = this.$store.state;
      let totalRX = 0;
      let totalTX = 0;
      if (item === 'rx') {
        cpesRatingList.forEach((cpe) => {
          totalRX += cpe.traffic_rx;
        });
        return totalRX;
      }
      if (item === 'tx') {
        cpesRatingList.forEach((cpe) => {
          totalTX += cpe.traffic_tx;
        });
        return totalTX;
      }
      return null;
    },
    totalClients(item) {
      const { cpesRatingList } = this.$store.state;
      let clients = 0;
      let LBSClients = 0;
      if (item === 'clients') {
        cpesRatingList.forEach((cpe) => {
          clients += cpe.connected_clients;
        });
        return clients;
      }
      if (item === 'lbs') {
        cpesRatingList.forEach((cpe) => {
          LBSClients += cpe.lbs_clients;
        });
        return LBSClients;
      }
      return null;
    },
    disableStatMode() {
      this.drPickerDateForStat = {
        startDate: '',
        endDate: ''
      };
      this.isShowingDateRangeLimitExceededErrorForStat = false;
      this.dateRangeLimitExceededErrorRangeLimitForStat = 0;
      this.statCpeId = '';
      this.$store.commit('setCpesStatId', null);
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    cutName(name) {
      return commonService.cutName(name);
    },
    cpeObj(id) {
      return commonService.cpeObjbyId(id);
    },
    // handleDateChangeForRating() {
    //   // console.log('change ts')
    //   // statCpeService.getCpesRating(this);
    //   this.refreshCpesRating();
    // },
    // handleDateChangeForStat() {
    //   statCpeService.getCpeStat(this);
    // },

    handleDateChangeForStat() {
      // statSystemService.responseStats(this);
      if (!this.isShowingDateRangeLimitExceededErrorForStat) {
        statCpeService.getCpeStat(this);
      }
    },
    handleDateChangeForRating() {
      // statSystemService.getSystemRating(this);
      if (!this.isShowingDateRangeLimitExceededErrorForRating) {
        this.refreshCpesRating();
      }
    },

    refreshCharts() {
      this.cpuCharts.series[0].data = [];
      this.memoryCharts.series[0].data = [];
      this.txRxChart.series[0].data = [];
      this.txRxChart.series[1].data = [];
      this.txRxChart.series[2].data = [];
      this.clientsChart.series[0].data = [];
      this.uniqueClientsChart.series[0].data = [];
      this.txRxRateChartPerUser.series[0].data = [];
      this.txRxRateChartPerUser.series[1].data = [];
      this.txRxRateChartPerUser.series[2].data = [];
      this.txRxRateChart.series[0].data = [];
      this.txRxRateChart.series[1].data = [];
      this.txRxRateChart.series[2].data = [];
    },
    getCpesRating() {
      // statCpeService.getCpesRatingWithLocationFilter(this);
      this.$store.commit('clearCpesRatingList')
      statCpeService.getCpesRatingWithLocationFilterWithPagination(this);
    },
    refreshCpesRating() {
      // statCpeService.getCpesRatingWithLocationFilter(this);
      this.filters.page.offset = 0;
      this.offset = 0;
      this.$store.commit('clearCpesRatingList')
      statCpeService.getCpesRatingWithLocationFilterWithPagination(this);
    },
    responseStats() {
      statCpeService.getCpeStat(this);
    },
    wlanObj(id) {
      return commonService.wlanObjbyId(id);
    },
    formatTime(value) {
      if (value) {
        if (this.isRuLocale) {
          return moment.duration(value, 'seconds').format('D дн., h ч., mm мин., ss сек.');
        }
        return moment.duration(value, 'seconds').format('D [day] h [h], mm [min.], ss [sec.]');
      } else {
        return value
      }
    }
  },
  // created() {
  //   this.$store.commit('clearCpesRatingList');
  //   this.filters.location = this.userBaseLocation;
  //   this.$store.commit('setCpesList', false);
  //   this.getCpesRating();
  //   this.disableStatMode();
  //   this.$store.commit('setCpesEditIndex', null);
  //   this.$store.commit('setCpesStatId', null);
  //   wlanService.getAllWlans(this);
  // },
  // created() {
  //   const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
  //   const goBackFromCpes = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromCpes');
  //   const needRestoreState = hasLocalStorageDataForRestoring && goBackFromCpes;
  //   if (needRestoreState) {
  //     this.loadComponentStateDataForRestoreAndRestore();
  //     this.removeComponentStateDataForRestore();
  //   }
  //   if (!needRestoreState && hasLocalStorageDataForRestoring) {
  //     this.removeComponentStateDataForRestore();
  //   }
  //
  //   if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormDashboard')) {
  //     // если переходим cюда из комопнента Ststistic - CPE
  //     // срзау делаем поиск по id этой точке
  //     this.filters.cpeId = this.$route.params.cpeIdFormDashboard;
  //     this.filters.with_childs = true;
  //     this.showGoBackToDashboardButton = true;
  //     // if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('showSelectedInDashboardCpeName')){
  //     //   this.showSelectedInDashboardCpeName = this.$route.params.showSelectedInDashboardCpeName
  //     // }
  //     // а далее логика  в вотчере isLoadingAll
  //   }
  //
  //   if (!needRestoreState) {
  //     this.$store.commit('clearCpesRatingList');
  //     this.filters.location = this.userBaseLocation;
  //     this.$store.commit('setCpesList', false);
  //     this.getCpesRating();
  //     this.disableStatMode();
  //     this.$store.commit('setCpesEditIndex', null);
  //     this.$store.commit('setCpesStatId', null);
  //   }
  //   wlanService.getAllWlans(this);
  // },

  created() {
    let inHeaderLocationSelectedApplying = true;
    const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
    const goBackFromCpes = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromCpes');
    const needRestoreState = hasLocalStorageDataForRestoring && goBackFromCpes;
    if (needRestoreState) {
      this.loadComponentStateDataForRestoreAndRestore();
      this.removeComponentStateDataForRestore();
    }
    if (!needRestoreState && hasLocalStorageDataForRestoring) {
      this.removeComponentStateDataForRestore();
    }

    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormDashboard')) {
      inHeaderLocationSelectedApplying = false

      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true
      }

      // если переходим cюда из комопнента Ststistic - CPE
      // срзау делаем поиск по id этой точке
      this.filters.cpeId = this.$route.params.cpeIdFormDashboard;
      this.filters.with_childs = true;
      this.showGoBackToDashboardButton = true;
      // if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('showSelectedInDashboardCpeName')){
      //   this.showSelectedInDashboardCpeName = this.$route.params.showSelectedInDashboardCpeName
      // }
      // а далее логика  в вотчере isLoadingAll
    }

    if (!needRestoreState) {
      this.$store.commit('clearCpesRatingList');
      this.filters.location = this.userBaseLocation;
      // console.log(this.userBaseLocation)

      if (this.$store.state.inHeaderLocationSelectModeEnabled &&
        inHeaderLocationSelectedApplying) {
        let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
        let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
        if (!locationForFiltering && locationForFiltering === '') {
          locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
          this.locationInFiltersData = commonService.locationObjbyId(locationForFiltering)
          this.filters.location = locationForFiltering
        } else {
          this.filters.location = locationForFiltering
          this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        }

        this.filters.with_childs = withChildsForFiltering;
      }

      this.$store.commit('setCpesList', false);
      this.disableStatMode();
      this.$store.commit('setCpesEditIndex', null);
      this.$store.commit('setCpesStatId', null);
    }
    // wlanService.getAllWlans(this);
    wlanService.getAllCompactWlansWithDataRemainingWithoutClients(this);
  },
  mounted() {
    // начальная загрузка статистики по точкам
    function initFilterQueryHandler(newSearchBarQuery) {
      if (newSearchBarQuery) {
        this.filters.query = newSearchBarQuery;
      } else {
        this.getCpesRating();
      }
    }

    // обновление статистики по точкам при изменении searchQuery параметра
    function updateFilterQueryHandler(newSearchBarQuery) {
      this.filters.query = newSearchBarQuery;
    }

    // подписки на изменение searchQuery
    this.subscribeOnSearchBarInit(initFilterQueryHandler);
    this.subscribeOnSearchBarUpdate(updateFilterQueryHandler);

    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      if (this.statVisible) {
        this.refreshCharts();
        this.responseStats();
      } else {
        this.refreshCpesRating();
      }
    });

  },

  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>

<style lang="scss">
  .multiselect-wrapper-for-select-wlans-in-statcpes{
    z-index: 9998;
  }
  .input-group-for-location-select-in-statcpes--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-statcpes--filters .select__list-container {
    width: 400px;
  }

.cpes {
  display: flex;
}

.cpes-rating-list {
  width: 100%;
}

.cpes-stat {
  display: inline-block;
  width: 0;
  word-wrap: break-word;
}

.stat-visible .cpes-rating-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 25%;
}

.stat-visible .cpes-stat {
  transition: width 0.2s;
  width: 75%;
}

.cpe-row {
  transition: background 0.15s;
  cursor: pointer;
}

.cpe-row.selected {
  background: #d9e3ec;
}

.cpe-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.cpe-row--sorted {
  background: rgba(236, 236, 236, 0.59);
}

.btn {
  cursor: pointer;
}

.table-row-move {
  transition: all 0.5s;
}

.save-icon {
  position: absolute !important;
  top: 10px;
  right: 30px;
}

.loader-backdrop-statcpes:after {
  position: absolute;
  top: 30px;
}
.switcher-with-childs-in-stat-cpes label.switch {
  margin-bottom: 0 !important;
}
</style>

<style lang="css" scoped>
.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}

  .limit-filter-wrapper{
    max-height: 29px !important;
  }
  .limit-filter{
    margin-top: -2em;
  }
.disabled-when-date-range-limit-exceeded-error {
  cursor: not-allowed !important;
}
.with-child {
  position: relative;
  font-size: 0.7em;
  padding: 0;
  margin: 0em;
  border: 0;
  top: -1px;
}
.with-child-wrapper {
  /*margin-left: 0.3em;*/
}
.switcher-with-childs-in-stat-cpes label.switch {
  margin: 0 !important;
}
.switcher-with-childs-in-stat-cpes label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-with-childs-in-stat-cpes span.switch-handle,
.switcher-with-childs-in-stat-cpes span.switch-label {
  height: 27px !important;
}
.switcher-with-childs-in-stat-cpes-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  margin-left: 5px !important;
  height: 27px !important;
}
.switcher-with-childs-in-stat-cpes-caption {
  /*margin: 0px !important;*/
  margin-left: -12px !important;
  text-align: center;
}
</style>
