/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы StatCpesTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  formatBytes,
  getMemoryLoad,
  getLocationNameById
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);
  const { areLocationsVisible } = additionalRules;

  return [
    {
      label: $t('general.model'),
      field: 'model',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.model
    },
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('general.trafficTxRx'),
      field: 'traffic',
      width: 'auto',
      type: 'text',
      sortFn: (x, y, col, rowX, rowY) => {
        // x - row1 value for column
        // y - row2 value for column
        // col - column being sorted
        // rowX - row object for row1
        // rowY - row object for row2

        const rowX_raffic_rx = rowX.nativeData.traffic_rx;
        const rowY_raffic_rx = rowY.nativeData.traffic_rx;

        if (rowX_raffic_rx < rowY_raffic_rx) {
          return -1;
        }

        if (rowX_raffic_rx > rowY_raffic_rx) {
          return 1;
        }

        return 0;
      },
      hidden: hiddenColumnRules.traffic
    },
    {
      label: $t('statistics.clients'),
      field: 'clients',
      width: 'auto',
      type: 'number',
      hidden: hiddenColumnRules.clients
    },
    {
      label: $t('statistics.passerbyClients'),
      field: 'passerbyClients',
      width: 'auto',
      type: 'number',
      hidden: hiddenColumnRules.passerbyClients
    },
    {
      label: $t('general.cpuLoad'),
      field: 'cpuLoad',
      width: 'auto',
      type: 'percentage',
      hidden: hiddenColumnRules.cpuLoad
    },
    {
      label: $t('general.memoryLoad'),
      field: 'memoryLoad',
      width: 'auto',
      type: 'percentage',
      hidden: hiddenColumnRules.memoryLoad
    },
    {
      label: $t('general.location'),
      field: 'location',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.location,
      visibilityDropdownSettings: {
        hidden: !areLocationsVisible
      }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ cpesData }) {
  return cpesData.map(cpe => {
    const {
      id,
      clients,
      cpu_load,
      desc,
      ip,
      mac,
      memory_free,
      memory_total,
      model,
      name,
      passerby_clients,
      sess_avg,
      sess_clients_count,
      sess_first_count,
      sess_return_count,
      sess_rx_bytes,
      sess_sum,
      sess_count,
      sess_tx_bytes,
      traffic_rx,
      traffic_rx_rate,
      traffic_tx,
      traffic_tx_rate,
      base_location
    } = cpe;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: cpe,
      model: model.name,
      // если нет имени, то показываем id
      name: name || `ID: ${id}`,
      traffic: (traffic_rx && traffic_tx) && `${formatBytes(traffic_rx)} / ${formatBytes(traffic_tx)}`,
      clients: sess_clients_count,
      passerbyClients: passerby_clients,
      cpuLoad: cpu_load,
      memoryLoad: (memory_total && memory_free) && getMemoryLoad(cpe.memory_total, cpe.memory_free),
      location: getLocationNameById(base_location)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
