import commonService from '@/services/commonService';

function formatBytes(bytes, decimals) {
  return commonService.formatBytesWithI18N(bytes, decimals);
}

function getMemoryLoad(total, free) {
  return ((total - free)) / total;
}

function getLocationNameById(id) {
  return commonService.showLocationFromId(id);
}

export {
  formatBytes,
  getMemoryLoad,
  getLocationNameById
};
